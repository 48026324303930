import React from "react";

import dynamic from 'next/dynamic';
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {DEFAULT} from "../helper/constantHelper";
import {pageStaticProps} from "../helper/utilHelper";

const Meta = dynamic(() => import('../components/meta/meta'));
const Account = dynamic(() => import('../components/Account/account'));

const Login = (props) => {
  const { meta, currLocale } = props;
  const { title, description, keywords, url_group } = meta || {};

  return (
    <>
      <Meta title={title}
            description={description}
            keywords={keywords}
            urlCategory={url_group}
            robotText={true}
            currLocale={currLocale } />
      <Account
        title="Login to Your Account"
        condition="Remember me"
        btntext="Sign in"
        text="Sign Up"
        linktext="Don’t have an account?"
        link={`/signup`}
        currLocale={currLocale}
      />
    </>
  );
}

export async function getStaticProps(context) {

  if(context.locale !== DEFAULT) {
    return {
      notFound: true
    }
  }

  const staticProps = await pageStaticProps('login', context);

  return {
    props: {
      ...staticProps,
      currLocale: context.locale,
      ...(await serverSideTranslations(context.locale, ['common']))
    },
    revalidate: 10
  }
}

export default Login;